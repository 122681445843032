export const technologyRows = [
  {
    image: 'how1',
    title: 'Tech Title 1',
    description: 'Tech Description 1'
  },
  {
    image: 'how2',
    title: 'Tech Title 2',
    description: 'Tech Description 2'
  },
  {
    image: 'how3',
    title: 'Tech Title 3',
    description: 'Tech Description 3'
  },
  {
    image: 'how4',
    title: 'Tech Title 4',
    description: 'Tech Description 4'
  },
  {
    image: 'how5',
    title: 'Tech Title 5',
    description: 'Tech Description 5'
  },
  {
    image: 'how6',
    title: 'Tech Title 6',
    description: 'Tech Description 6'
  },
]