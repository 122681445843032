import React, { useState, useEffect } from "react"
import { __ } from '../../../../utils/i18n'

import { renderInnovation} from '../02_Innovation'
import { renderTechnology} from '../03_OurTech'


export const sectionOurTechnology = (t) => {
  return (
    <>
      {renderInnovation({t, showLearnMore: true })}
      {renderTechnology(t)}
      </>
  )
}

