import React, { useState, useEffect } from "react"
import { __ } from '../../../../utils/i18n'
import './OurTech.style.scss'
import { technologyRows} from './technologyData'
// import Img from "gatsby-image"

import how1 from 'images/how-to-1.webp'
import how2 from 'images/how-to-2.webp'
import how3 from 'images/how-to-3.webp'
import how4 from 'images/how-to-4@3x.webp'
import how5 from 'images/how-to-5.webp'
import how6 from 'images/how-to-6.webp'


let icons = {
  how1,
  how2,
  how3,
  how4,
  how5,
  how6,
}

const renderTechnologyRow = ({ image, title, description, i }, t) => {
  return (
    <div key={i} className="row technology-row">
      <div className="col-lg-3 col-md-4">
        <div className="img-wrap">
          <img src={icons[image]} alt={__(title, t)} />
          {/* <Img fixed={icons[image]} alt={__(title, t)} /> */}
        </div>
      </div>
      <div className="col-lg-9 col-md-8 details">
        <div className="inner">
          <h3 className="mb-1">{__(title, t)}</h3>
          <p className="subtitle">{__(description, t)}</p>
        </div>
      </div>
    </div>
  )
}


export const renderTechnology = (t) => {
  return (
    <section className="section-technology sec-pad-std">
    <div className="inner container-sm">
      <header>
        <h2 className="mb-1">{__('Our Technology', t)}</h2>
        <p className="subtitle mb-2">{__('Technology Subtitle', t)}</p>
        <p className="subtitle mb-2">{__('Technology Subtitle 2', t)}</p>
        <button className="m-btn m-btn-white">{__('Create Account', t)}</button>
      </header>
      {technologyRows.map(({ image, title, description }, i) => {
        return renderTechnologyRow({ image, title, description, i }, t)
      })}
    </div>
  </section>
  )

}