import React from "react"
import "./Intro.style.scss"
import { __ } from "../../../../utils/i18n"
// import heroImage from "images/intro-image.png"
import PlatformPreview from "images/farmair-landing.mp4"
import EITImage from "images/eit.png"

export const renderIntro = t => {
  return (
    <>
      <section className="section-intro">
        <div className="container-sm">
          <div className="row">
            <div className="col-xl-5 left col-lg-6 col-md-12">
              <div className="inner">
                <header>
                  <h1 className="mb-1">{__("Intro Title", t)}</h1>
                  <p className="subtitle mt-1 mb-0 mb-lg-3">
                    {__("Intro Subtitle", t)}
                  </p>
                  <div className="btn-wrap">
                    <a
                      href="https://app.farmair.io/signup"
                      className="m-btn m-btn-green"
                    >
                      {__("Create Account", t)}
                    </a>
                  </div>
                </header>
              </div>
            </div>
            <div className="col-xl-7 col-lg-6 col-md-12 right">
              <div className="img-wrap">
                <video controls autoplay>
                  <source src={PlatformPreview} type="video/mp4" />
                </video>
                {/* <img src={heroImage} alt="farmAIr field" className="img-fluid" /> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="bg-light py-4">
        <img
          src={EITImage}
          alt="EIT logo"
          style={{
            width: 500,
            maxWidth: "90%",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        />
      </div>
    </>
  )
}
