import React from 'react'
import { __ } from '../../../../utils/i18n'
import ourPlatform from 'images/platform-3x.webp'
import './OurPlatform.style.scss'

export const renderOurPlatform = (t) => {
  return (
    <section className="section-platform sec-pad-std bg-white">
    <div className="inner container-sm">
      <header className="text-center mb-3 mb-sm-5">
        <h2 className="mb-1">{__('Our platform', t)}</h2>
        <p className="subtitle mb-2 mx-auto">{__('Our Platform Subtitle', t)}</p>
      </header>
      <div className="img-wrap">
        <img src={ourPlatform} alt={__('Our platform', t)}
          className="img-fluid mx-auto" />
      </div>
    </div>
  </section>
  )
}