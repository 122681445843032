import React, { useState, useEffect } from "react"
// import DynamicComponent from "./DynamicComponent"
import SbEditable from "storyblok-react"
import "./Landing.style.scss"
import { renderIntro } from "./sections/01_Into"
import { sectionOurTechnology } from "./sections/OurTechonology"
import { renderWhatWeOffer } from "./sections/04_WeOffer"
import { renderOurPlatform } from "./sections/05_OurPlatform"
import { renderOurPartners } from "./sections/06_OurPartners"
import { renderOurBlog } from "./sections/08_OurBlog"
// import Pricing from 'component/Pricing'

const renderSections = props => {
  const { t } = props

  return (
    <>
      {renderIntro(t)}
      {sectionOurTechnology(t)}
      {renderOurPlatform(t)}
      {renderWhatWeOffer(t)}
      {/* <Pricing t={t} /> */}
      {renderOurPartners(t)}
      {renderOurBlog(props)}
    </>
  )
}

const Page = props => {
  const { t } = props

  return (
    <div className="landing-page fade-in">
      {t ? renderSections(props) : "loading...."}
    </div>
  )
}

export default Page
