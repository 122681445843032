import React from "react"
import { weOfferRows } from "./weOfferData"
import { __ } from "../../../../utils/i18n"

//TODO: remove from assets folder
// import shape from "images/shape.svg"
// import dollar from "images/dollar.svg"
// import discount from "images/discount.svg"
// import screens from "images/screens.svg"
// import map from "images/map.svg"
// import history from "images/history.svg"
// import user from "images/user.svg"

import weatherData from "images/01-weather-data.png"
import earlyDetection from "images/02-early-accurate-detection.png"
import soilData from "images/03-soil-data.png"
import stressClassification from "images/04-stress-classification.png"
import vegetationWaterIndexes from "images/05-vegetation-water-indexes.png"
import model3d from "images/06-3d-model.png"
import lowChlorophyllContent from "images/07-low-chlorophyll-content.png"
import navigateToProblematicPlants from "images/08-navigate-to-problematic-plants.png"
import digitalSurfaceMap from "images/09-digital-surface-map.png"
import stressDetectionReport from "images/10-stress-detection-report.png"

import "./WeOffer.style.scss"

let weOfferIcons = {
  weatherData,
  earlyDetection,
  soilData,
  // stressClassification,
  vegetationWaterIndexes,
  model3d,
  lowChlorophyllContent,
  navigateToProblematicPlants,
  digitalSurfaceMap,
  stressDetectionReport,
  // shape,
  // dollar,
  // discount,
  // screens,
  // map,
  // history,
  // user
}

const renderOfferColumn = ({ icon, description, title, t, i }) => {
  return (
    <div key={i} className="col-md-6 col-sm-12 col-12 col-xs-6">
      <div className="inner">
        <div className="inner-left">
          <div className="icon-wrap my-1 my-md-2">
            <div className="icon mx-auto">
              <img src={weOfferIcons[icon]} alt={description} className="" />
            </div>
          </div>
        </div>
        <div className="inner-right">
          <h3>{__(title, t)}</h3>
          <p className="">{__(description, t)}</p>
        </div>
      </div>
    </div>
  )
}

export const renderWhatWeOffer = t => {
  return (
    <section className="section-we-offer sec-pad-std bg-white">
      <div className="inner container-sm">
        <header className="text-center">
          <h2 className="mb-1">{__("What we offer", t)}</h2>
          <p className="subtitle mb-2 mx-auto">{__("We Offer Subtitle", t)}</p>
        </header>
        <div className="row we-offer-row">
          {weOfferRows.map(({ icon, description, title }, i) => {
            return renderOfferColumn({ icon, description, title, t, i })
          })}
        </div>
      </div>
    </section>
  )
}
