const innoHeight = "160px"

export const weOfferRows = [
  {
    icon: `weatherData`,
    title: "We Offer Title 1",
    description: "We Offer Description 1",
  },
  {
    icon: `earlyDetection`,
    title: "We Offer Title 2",
    description: "We Offer Description 2",
  },
  {
    icon: `soilData`,
    title: "We Offer Title 3",
    description: "We Offer Description 3",
  },
  // {
  //   icon: `stressClassification`,
  //   title: 'We Offer Title 4',
  //   description: "We Offer Description 4",
  // },
  {
    icon: `vegetationWaterIndexes`,
    title: "We Offer Title 5",
    description: "We Offer Description 5",
  },
  // {
  //   icon: `model3d`,
  // title: 'We Offer Title 6',

  //   description: "We Offer Description 6",
  // },
  {
    icon: `lowChlorophyllContent`,
    title: "We Offer Title 7",
    description: "We Offer Description 7",
  },
  {
    icon: `navigateToProblematicPlants`,
    title: "We Offer Title 8",
    description: "We Offer Description 8",
  },
  {
    icon: `digitalSurfaceMap`,
    title: "We Offer Title 9",
    description: "We Offer Description 9",
  },
  {
    icon: `stressDetectionReport`,
    title: "We Offer Title 10",
    description: "We Offer Description 10",
  },
]
