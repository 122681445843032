import React from 'react'
import { __ } from '../../../../utils/i18n'
import './OurPartners.style.scss'

import partner1el from 'images/american-farmschool-el.webp'
import partner1 from 'images/american-farmschool-en.webp'
import partner2 from 'images/partner-2.webp'

export const renderOurPartners = (t) => {
  console.log('t the t', t)
  return (
    <section className="section-partners sec-pad-std bg-white">
    <div className="inner container-sm">
      <header className="text-center mb-3">
        <h2 className="mb-1">{__('Our Partners', t)}</h2>
        <p className="subtitle mb-2 mx-auto">{__('Our Partners Subtitle', t)}</p>
      </header>
      <div className="img-wrap">
        { t === 'el' ? 
          <img src={partner1el} alt="Partners"
          className="img-fluid partner-1" />
        : <img src={partner1} alt="Partners"
        className="img-fluid partner-1" />}
        <img src={partner2} alt="Partners"
          className="img-fluid partner-2" />
      </div>
    </div>
  </section>
  )
}